<template>
  <div class="page-login">
    <div class="login-box bg-white">
      <h2 class="text-center">千禾会员登录</h2>
      <a-tabs class="v-mt-lg" v-model="loginType" size="large" :tab-bar-style="{ marginBottom: '24px' }">
        <a-tab-pane :key="1" tab="密码登录" size="large">
          <a-form-model ref="ruleForm" :model="form">
            <a-form-model-item prop="username">
              <a-input size="large" v-model="form.username" placeholder="用户名" ref="username" @pressEnter="onSubmit" autocomplete="off">
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password">
              <a-input-password size="large" v-model="form.password" placeholder="密码" ref="password" @pressEnter="onSubmit" autocomplete="new-password">
                <a-icon slot="prefix" type="lock" />
              </a-input-password>
            </a-form-model-item>
          </a-form-model>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="验证码登录" size="large">
          <a-form-model ref="ruleForm" :model="form">
            <a-form-model-item prop="phone">
              <a-input size="large" v-model="form.phone" placeholder="手机号码" ref="phone" @pressEnter="onSubmit">
                <a-icon slot="prefix" type="mobile" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="smscode">
              <a-input size="large" v-model="form.smscode" placeholder="短信验证码" ref="smscode" @pressEnter="onSubmit">
                <a-icon slot="prefix" type="mail" />
                <span slot="suffix" class="sms-send text-primary cursor-pointer" @click="showBox" v-if="!verified">
                  <template v-if="verifying">发送中...</template>
                  <template v-else>发送验证码</template>
                </span>
                <span slot="suffix" class="sms-send text-grey" v-else>
                  重新发送（
                  <i>{{ second }}</i>
                  s）
                </span>
              </a-input>
            </a-form-model-item>
            <Verify @success="verifySuccess" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
          </a-form-model>
        </a-tab-pane>
      </a-tabs>
      <div class="text-center"><a-button class="full-width" type="primary" size="large" @click="onSubmit" :loading="loginLoading">登录</a-button></div>
      <div class="text-right v-my-md">
        <router-link class="hover-line" :to="{ path: 'regist' }">会员注册</router-link>
        <router-link class="hover-line v-ml-md" :to="{ path: 'reset' }">忘记密码？</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { login, loginBySms, loginSms } from '@/api/user'
import Verify from './common/captcha/Verify'
export default {
  name: 'Login',
  components: {
    Verify,
  },
  data() {
    return {
      loginType: 1,
      loginLoading: false,
      form: {
        username: '',
        password: '',
        phone: '',
        smscode: '',
      },
      verifying: false,
      verified: false,
      second: 60,
    }
  },
  watch: {
    second(val) {
      if (val <= 0) {
        this.verified = false
        this.clearTimer()
      }
    },
  },
  methods: {
    startCountdown() {
      this.clearTimer()
      let vm = this
      const countdown = () => {
        vm.second--
      }
      vm.timer = setInterval(() => {
        countdown()
      }, 1000)
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    showBox() {
      this.$message.destroy()
      let { phone } = this.form
      if (!this.$utils.isValidPhone(phone)) {
        this.$message.warning('请输入正确的手机号码')
        this.$refs.phone.focus()
        return
      }
      this.$refs.verify.show()
    },
    verifySuccess(params) {
      this.$message.destroy()
      let { phone } = this.form
      this.verifying = true
      loginSms({ phone, captchaVerification: params.captchaVerification })
        .then(({ data }) => {
          this.verifying = false
          if (data.success) {
            this.$message.success('验证码已发送至手机')
            this.verified = true
            this.second = 60
            this.startCountdown()
          } else {
            this.$message.warning(data.msg)
          }
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
          this.verifying = false
        })
    },
    handleLogin(data) {
      let vm = this
      if (data.success) {
        let { token, userinfo } = data.result
        vm.$store.dispatch('logout')
        if (userinfo.state === 0) {
          this.$confirm({
            icon: 'info-circle',
            title: '您注册的账号尚未提交社区注册资料，无法登入社区。',
            okText: '去填报注册资料',
            cancelText: '关闭',
            // centered: true,
            onOk() {
              vm.$store.dispatch('login', { token, userinfo })
              vm.$router.push('regist?action=fill')
            },
          })
        } else if (userinfo.state === -1) {
          this.$confirm({
            icon: 'info-circle',
            title: '您注册的账号所提交的资料未通过审核，无法登入社区。',
            okText: '去重新填报注册资料',
            cancelText: '关闭',
            // centered: true,
            onOk() {
              vm.$store.dispatch('login', { token, userinfo })
              vm.$router.push('regist?action=fill')
            },
          })
        } else if (userinfo.state === 1) {
          this.$info({
            icon: 'info-circle',
            title: '您填报的注册资料正在审核中，无法登入社区，审核结果将通过短信发至您的手机，请耐心等待。',
            // centered: true,
          })
        } else {
          vm.$store.dispatch('login', { token, userinfo })
          this.$router.push('/usercenter')
        }
      } else {
        this.$message.warning(data.msg)
      }
    },
    onSubmit() {
      this.$message.destroy()
      let { username, password, phone, smscode } = this.form
      if (this.loginType === 1) {
        if (username.trim() === '') {
          this.$message.warning('请输入用户名')
          this.$refs.username.focus()
          return
        }
        if (password === '') {
          this.$message.warning('请输入密码')
          this.$refs.password.focus()
          return
        }
        this.submiting = true
        login(username, password)
          .then(({ data }) => {
            this.submiting = false
            this.handleLogin(data)
          })
          .catch(err => {
            if (err) {
              this.$message.warning(err)
            }
            this.submiting = false
          })
      } else if (this.loginType === 2) {
        if (!this.$utils.isValidPhone(phone)) {
          this.$message.warning('请输入正确的手机号码')
          this.$refs.phone.focus()
          return
        }
        if (smscode === '') {
          this.$message.warning('请输入短信验证码')
          this.$refs.smscode.focus()
          return
        }
        this.submiting = true
        loginBySms(phone, smscode)
          .then(({ data }) => {
            this.submiting = false
            this.handleLogin(data)
          })
          .catch(err => {
            if (err) {
              this.$message.warning(err)
            }
            this.submiting = false
          })
      }
    },
  },
}
</script>
<style lang="stylus" scoped></style>
